import React, { Key, useState } from "react";
import { Transfer, Tree, Checkbox, Input, Select, Space } from "antd";
import type { GetProp, TransferProps, TreeDataNode } from "antd";
import { mustArray } from "../../utils";
import { useTranslation } from "react-i18next";
import GlobalNoData from "../GlobalNoData";

type TransferItem = GetProp<TransferProps, "dataSource">[number];
interface TreeTransferType {
  status?: TransferProps["status"];
  dataSource: TreeDataNode[] | any[];
  flatSource?: any[];
  disabled?: boolean;
  isInstaller?: boolean;
  isStation?: boolean;
  collapseItem?: any;
  selectedKeys?: any[];
  searchPlaceholder?: string;
  searchValue?: string;
  showSearch?: boolean;
  isMore?: { more: boolean; pageIndex: number } | null;
  targetKeys: TransferProps["targetKeys"];
  onChange: TransferProps["onChange"];
  onSearch?: any;
}

const generateTree = (
  treeNodes: TreeDataNode[] = [],
  checkedKeys: TreeTransferType["targetKeys"] = []
): TreeDataNode[] =>
  treeNodes &&
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key as string),
    children: generateTree(children, checkedKeys),
  }));

const TreeTransfer: React.FC<TreeTransferType> = ({
  dataSource,
  flatSource,
  targetKeys = [],
  isInstaller,
  isStation,
  collapseItem,
  onSearch,
  showSearch,
  searchValue,
  isMore,
  disabled,
  searchPlaceholder,
  ...restProps
}) => {
  const transferDataSource: TransferItem[] = [];
  const { t } = useTranslation();
  const flatten = (list: TreeDataNode[] = []) => {
    list &&
      list.forEach((item) => {
        transferDataSource.push(item as TransferItem);
        flatten(item.children);
      });
  };
  console.log(targetKeys, "targetKeys");
  flatten(dataSource);

  const searchChildrenKey = (node: any, arr: any[]) => {
    node.forEach((item: any) => {
      console.log(item, "32432432");
      arr.push(item.key);
      if (item.children) searchChildrenKey(item.children, arr);
    });
  };
  const getChildrenId = (value: number, arr: any[]) => {
    const childrenId = transferDataSource.filter(
      (item: any) => item.parentId === value
    );
    if (childrenId) {
      childrenId.forEach((item) => {
        arr.push(item.id);
        if (item.children) {
          getChildrenId(item.id, arr);
        }
      });
    }
  };
  const [searchObj, setSearchObj] = useState({
    selectVal: 1,
    inputVal: "",
  });
  console.log(generateTree(dataSource, targetKeys), "dataSource");

  const treeDom = (
    checkedKeys: React.Key[],
    selectedKeys: Key[],
    onItemSelectAll: (keys: Key[], selected: boolean) => void,
    onItemSelect: (key: Key, selected: boolean) => void
  ) => {
    return (
      <div style={{ padding: 4 }}>
        {showSearch && (
          <Space.Compact className="flex flex-row flex-1 mb-2 mx-3">
            <Select
              disabled={disabled}
              defaultValue={1}
              options={[
                {
                  value: 1,
                  label: t("staffDimensionsInstaller"),
                },
                {
                  value: 2,
                  label: t("staffDimensionsSite"),
                },
              ]}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  selectVal: e,
                });
                onSearch(searchObj.inputVal, e);
              }}
              value={searchObj.selectVal}
            />
            <Input
              disabled={disabled}
              value={searchObj.inputVal}
              placeholder={
                searchObj.selectVal === 2
                  ? `${t("comEnter")}${t("comFieldStationName")}`
                  : `${t("comEnter")}${t("comFieldIN")}`
              }
              onChange={(e: any) => {
                setSearchObj({
                  ...searchObj,
                  inputVal: e.target.value,
                });
                onSearch(e.target.value, searchObj.selectVal);
              }}
            />
          </Space.Compact>
        )}

        {isStation && !dataSource?.[0]?.children?.length ? null : (
          <Tree
            disabled={
              disabled || (isStation && !dataSource?.[0]?.children?.length)
            }
            showIcon
            blockNode
            checkable
            height={233}
            checkStrictly={isInstaller}
            defaultExpandAll
            checkedKeys={checkedKeys}
            treeData={generateTree(dataSource, targetKeys)}
            onCheck={(_, { node: { key }, checked, node }: any) => {
              if (isStation) {
                if (node.children && node.children?.length > 0) {
                  const arr = [key];
                  searchChildrenKey(node.children, arr);
                  onItemSelectAll(arr as string[] | number[], checked);
                } else {
                  onItemSelectAll(
                    checked
                      ? (_ as string[] | number[])
                      : [key, dataSource?.[0].id],
                    checked
                  );
                }
              } else {
                if (node.children) {
                  const arr = [key];
                  searchChildrenKey(node.children, arr);
                  console.log(arr, "ewrewr");
                  onItemSelectAll(
                    arr as string[] | number[],
                    !selectedKeys.includes(key as number)
                  );
                } else {
                  onItemSelect(
                    key as string | number,
                    !selectedKeys.includes(key as number)
                  );
                }
              }
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Transfer
      {...restProps}
      showSelectAll
      // selectedKeys={selectedKeys}
      // onSelectChange={onSelectChange}
      // showSearch={showSearch}
      locale={{
        searchPlaceholder: searchPlaceholder || t("sysOpenApiPleaseSelect"),
      }}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="openapi-tree-transfer"
      render={(item) => item.label || item.title}
      listStyle={{
        width: "100%",
        height: "300px",
        overflowY: "auto",
        paddingTop: 12,
      }}
    >
      {({ direction, onItemSelect, onItemSelectAll, selectedKeys }) => {
        if (direction === "left") {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          return treeDom(
            checkedKeys,
            selectedKeys,
            onItemSelectAll,
            onItemSelect
          );
        }

        if (direction === "right" && !isStation) {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          return (
            <div className="grid gap-2 pl-3">
              {checkedKeys.length > 0 && (
                <Checkbox
                  disabled={disabled}
                  checked={
                    selectedKeys.length === [...new Set(checkedKeys)].length
                  }
                  onChange={(val) => {
                    const value = val.target.checked;
                    onItemSelectAll(checkedKeys, value ? true : false);
                  }}
                >
                  <span
                    className={disabled ? "text-cDisableTxt" : "text-cMainTxt"}
                  >
                    {" "}
                    {t("selectAll")}
                  </span>
                </Checkbox>
              )}
              {checkedKeys.length > 0 ? (
                mustArray(flatSource).map((ele: any) => {
                  return checkedKeys.indexOf(ele.id) === -1 ||
                    checkedKeys.indexOf(ele.parentId) !== -1 ? null : (
                    <Checkbox
                      disabled={disabled}
                      style={{
                        color: disabled
                          ? "var(--color-textDisable) !important"
                          : "var(--color-textMain) !important",
                      }}
                      key={ele.id}
                      checked={selectedKeys.indexOf(ele.key) !== -1}
                      value={ele.id}
                      onChange={(val) => {
                        console.log(val);
                        const value = val.target.value;
                        const res: any[] = [];
                        getChildrenId(value, res);
                        if (res.length >= 1) {
                          const arr = [value].concat(res);
                          onItemSelectAll(
                            arr,
                            selectedKeys.includes(value) ? false : true
                          );
                        } else {
                          onItemSelectAll(
                            [value] as string[] | number[],
                            selectedKeys.includes(value) ? false : true
                          );
                        }
                      }}
                    >
                      {ele.label}
                    </Checkbox>
                  );
                })
              ) : (
                <GlobalNoData className="mt-[50px]" />
              )}
            </div>
          );
        }

        if (direction === "right" && isStation) {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          console.log(flatSource, "flatSourceflatSource");
          console.log(transferDataSource, "transferDataSource");
          console.log(checkedKeys, "checkedKeyscheckedKeys");
          console.log(selectedKeys, "selectedKeys");
          const res: any =
            flatSource && mustArray(flatSource)
              ? flatSource[0]?.children
                ? flatSource[0]?.children
                : flatSource
              : flatSource;
          return (
            <div className="grid gap-2 pl-3">
              {checkedKeys.length > 0 && (
                <Checkbox
                  disabled={disabled}
                  checked={
                    selectedKeys.length === [...new Set(checkedKeys)].length
                  }
                  onChange={(val) => {
                    const value = val.target.checked;
                    onItemSelectAll(checkedKeys, value ? true : false);
                  }}
                >
                  <span
                    className={disabled ? "text-cDisableTxt" : "text-cMainTxt"}
                  >
                    {" "}
                    {t("selectAll")}
                  </span>
                </Checkbox>
              )}

              {checkedKeys.length > 0 ? (
                res.map((ele: any) => {
                  return checkedKeys.indexOf(ele.key) === -1 ? null : (
                    <Checkbox
                      disabled={disabled}
                      style={{
                        color: disabled
                          ? "var(--color-textDisable) !important"
                          : "var(--color-textMain) !important",
                      }}
                      key={ele.key}
                      value={ele.key}
                      checked={selectedKeys.indexOf(ele.key) !== -1}
                      onChange={(val) => {
                        console.log(selectedKeys, "targetKeys");
                        const value = val.target.value;
                        const checked = val.target.checked;
                        const parentId = flatSource ? flatSource[0]?.id : "";
                        // let selectedKeysNum = selectedKeys.filter(
                        //   (item: any) => typeof item !== "string"
                        // );
                        // const checkedKeysNum = checkedKeys.filter(
                        //   (item: any) => typeof item !== "string"
                        // );
                        // if (!selectedKeys.includes(value) && checked) {
                        //   selectedKeysNum.push(value);
                        // } else if (selectedKeys.includes(value) && !checked) {
                        //   selectedKeysNum = selectedKeysNum.filter(
                        //     (item: any) => item !== value
                        //   );
                        // }
                        // const isSame =
                        //   selectedKeysNum.length ===
                        //   [...new Set(checkedKeysNum)].length;
                        // setStationAll(isSame);
                        // 这里必须用选择组选择否则会报错
                        onItemSelectAll(
                          [value, parentId],
                          selectedKeys.includes(value) ? false : true
                        );
                      }}
                    >
                      {ele.title}
                    </Checkbox>
                  );
                })
              ) : (
                <GlobalNoData className="mt-[50px]" />
              )}
            </div>
          );
        }
      }}
    </Transfer>
  );
};

export default TreeTransfer;

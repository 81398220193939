import { useEffect } from "react";

const REQUEST_DATA_CODE_ERROR_MESSAGE = "REQUEST_DATA_CODE_ERROR_MESSAGE";

/**
 * 监听错误码
 *
 * 当接口请求返回错误吗与传入的code匹配时,将执行回调
 *
 * @param code
 * @param callback
 */
export const useResponseErrorCode = (
  code: number,
  callback?: (data: any) => void
) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.detail.content?.code === code) {
        callback && callback(event.detail.content);
      }
    };
    window.addEventListener(REQUEST_DATA_CODE_ERROR_MESSAGE, listener);
    return () => {
      window.removeEventListener(REQUEST_DATA_CODE_ERROR_MESSAGE, listener);
    };
  }, [callback]);
};

export const postResponseErrorCode = (data: any) => {
  const event = new CustomEvent(REQUEST_DATA_CODE_ERROR_MESSAGE, {
    detail: { content: data },
  });
  window.dispatchEvent(event);
};

import React, { cloneElement } from "react";
import { isEmptyArray, mustArray } from "../../utils";
import { permsListAtom } from "@/jotai/userInfo";
import { useAtom } from "jotai";

export const OperateBoxContext = React.createContext<any>({});
OperateBoxContext.displayName = "OperateBox";
interface OperateBoxProps {
  children?: React.ReactNode;
  items?: any[];
}

const OperateBox = ({ children, items }: OperateBoxProps) => {
  const [permsList] = useAtom(permsListAtom);
  const currentValue = {
    permsList: permsList,
  };

  return (
    <div className=" flex items-center gap-5">
      <OperateBoxContext.Provider value={currentValue}>
        {children}
        {!isEmptyArray(items)
          ? mustArray(items).map((item, index) => {
              return <div key={`${index + 1}`}>{item}</div>;
            })
          : null}
      </OperateBoxContext.Provider>
    </div>
  );
};
export default OperateBox;

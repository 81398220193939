import { Constant } from "@/utils/constants";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import { CHANGE_LANG } from "../config";
import { Console } from "console";
import { useTranslation } from "react-i18next";

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export const localGetString = (key: string) => {
  const value = window?.localStorage?.getItem(key) || "";
  return value;
};

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export const localGetObj = (key: string) => {
  const value = window?.localStorage?.getItem(key) || "";
  try {
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
};

/**
 * @description 存储localStorage
 * @param {String} key Storage名称
 * @param {Any} value Storage值
 * @return void
 */
export const localSet = (key: string, value: string) => {
  window?.localStorage?.setItem(key, value);
};

/**
 * @description 清除localStorage
 * @param {String} key Storage名称
 * @return void
 */
export const localRemove = (key: string) => {
  window?.localStorage?.removeItem(key);
};

/**
 * @description 清除所有localStorage
 * @return void
 */
export const localClearAll = () => {
  window?.localStorage?.clear();
};

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return number
 */
export function randomNum(min: number, max: number): number {
  const num = Math.floor(Math.random() * (min - max) + max);
  return num;
}

/**
 * @description 生成树结构
 * @param {Number} arr 平铺列表
 * @param {Number} parentId 顶级父级id
 * @return number
 */
export function arrayToTree(arr: any[], parentId: number = 0) {
  // 将所有对象存到 map 中
  const map = arr.reduce((prev, cur) => {
    // 注意：这里是浅拷贝，会修改原数组，当后续再使用原数组会出问题
    prev[cur.id] = cur;
    return prev;
  }, {});
  // 定义返回结果数组
  const result = [];
  // 遍历传入的对象
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const findItem = arr.find((obj) => obj?.id === item.parentId);
    // 当遍历到的对象的 pid 等于 传入的根节点的 id，说明为根节点，直接 push 到数组中
    if (item.parentId === parentId || !findItem?.id) {
      result.push(item);
      // 退出本次循环
      continue;
    }
    // 如果不是根节点，从 map 中找到 pid 对应的对象，该对象即为当前遍历到的对象的父节点
    const parent = map[item.parentId];
    if (parent) {
      // 给父节点添加 children 属性,并定义为数组
      parent.children = parent.children || [];
      // 在数组中 push 当前子节点
      parent.children.push(item);
    }
  }
  return result;
}

/**
 *
 * 从树状结构的数组中找出某条数据
 * @param treeData
 * @param key 数组里面对象的某个键
 * @param value
 * @returns any
 */
export const findItemFromTreeData: any = (
  treeData: any[],
  key: string,
  value: any
) => {
  if (!Array.isArray(treeData)) {
    return {};
  }

  for (const item of treeData) {
    if (item[key] === value) {
      return item;
    }

    if (item.children) {
      const findObj = findItemFromTreeData(item.children, key, value);
      if (findObj && findObj[key] != undefined) {
        return findObj;
      }
    }
  }

  return {};
};

/**
 * @description 判断数据类型
 * @param {Any} val 需要判断类型的数据
 * @return string
 */
export const getDataType = (val: any) => {
  if (val === null) return "null";
  if (val === undefined) return "undefined";
  if (typeof val !== "object") {
    return typeof val;
  } else {
    return Object.prototype.toString
      ?.call(val)
      ?.slice(8, -1)
      ?.toLocaleLowerCase();
  }
};

/**
 * @description 判断数据是否存在
 * @param {Any} val 需要判断类型的数据
 * @return boolean
 */
export function isExist(val: any): boolean {
  return val !== null && val !== undefined && val !== "";
}

/**
 * @description 判断数据是否不存在
 * @param {Any} val 需要判断类型的数据
 * @return boolean
 */
export function notExist(val: any): boolean {
  return !isExist(val);
}

/**
 * @description 判断数据是空数组
 * @param {val} val 需要判断类型的数据
 * @return boolean
 */
export function isEmptyArray(val: any): boolean {
  if (!Array.isArray(val)) return true;
  return val.length === 0;
}

/**
 * @description 判断数据不是空数组
 * @param {array} array 需要判断类型的数据
 * @return boolean
 */
export const notEmptyArray = (array: any): boolean => !isEmptyArray(array);
/**
 * @description 数据必须是个数组
 * @param {val} val 需要判断类型的数据
 * @return []
 */
export function mustArray(val: any): any[] {
  if (Array.isArray(val)) return val;
  if (!isExist(val)) return [];
  try {
    return Array.from(val);
  } catch (e) {
    return [];
  }
}

/**
 * @description 数据是否是个数组
 * @param {val} val 需要判断类型的数据
 * @return []
 */
export const isArray =
  Array.isArray ||
  function (arg) {
    return Object.prototype.toString.call(arg) === "[object Array]";
  };
/**
 * @description 判断数据是不是函数
 * @param {val} val 需要判断类型的数据
 * @return boolean
 */
export const isFunction = (x: any): boolean => typeof x === "function";

/**
 * @description 保留两位小数，处理精度问题
 * @param {val} val 需要处理的数据
 * @return number
 */
export function toFixed(num: number) {
  return Math.round(num * 100) / 100;
}

/**
 * @description 日期转为星期
 * @param dateString 需要转换的日期
 * @returns
 */

export const getDayOfWeek = (
  dateString: string | number | Date,
  curLang: string,
  t: any
) => {
  try {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayCns = [
      t("monday"),
      t("tuesday"),
      t("wednesday"),
      t("thursday"),
      t("friday"),
      t("saturday"),
      t("sunday"),
    ];
    const dayFin = curLang === "zh-CN" ? dayCns : days;
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return dayCns[dayOfWeek];
  } catch (error) {
    return "--";
  }
};
export function getObjValue(obj: any, keys: string[]): any {
  if (!Array.isArray(keys) || keys.length === 0) {
    throw new Error("Invalid keys input for getObjValue");
  }
  const value = obj[keys[0]];
  if (keys.length === 1) {
    return value;
  } else {
    return getObjValue(value, keys.slice(1));
  }
}
/**
 * @description 对象数组分组
 * @param list 原数组
 * @param key 字符串， 或者数组
 * @returns 返回[[],[],[]] 分组后的二维数组
 */

export function groupBy(
  list: any[],
  key: string | Array<string> = "id"
): any[] {
  if (isEmptyArray(list)) return [];
  const recordMap = new Map();
  if (typeof key === "string") {
    list.forEach((x) => {
      const groupKey = x[key];
      if (recordMap.has(groupKey)) {
        recordMap.set(groupKey, [...recordMap.get(groupKey), x]);
      } else {
        recordMap.set(groupKey, [x]);
      }
    });
  } else {
    list.forEach((x) => {
      const fieldVal = getObjValue(x, key);
      if (recordMap.has(fieldVal)) {
        recordMap.set(fieldVal, [...recordMap.get(fieldVal), x]);
      } else {
        recordMap.set(fieldVal, [x]);
      }
    });
  }
  return [...recordMap.values()];
}

export const isContainChineseString = (str: string | undefined) => {
  if (str == undefined) return false;
  return /[\u4e00-\u9fff]/.test(str);
};

/**
 * @description 判断text是数字就保留两位小数， 是null 就返回--， 是文字就直接返回
 * @param text 内容
 * @returns text || 两位小数 || --
 */

export const isExistText = (text: number | string | null) => {
  if (notExist(text)) return "--";
  if (typeof text === "number") {
    return toFixed(text);
  }
  return text;
};

export const systemIsAndroidOrIosOrPc = () => {
  const userAgent = window.navigator.userAgent;
  if (/Android/i.test(userAgent)) {
    return "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  } else {
    return "PC";
  }
};

/**
 * @description Form报错定位
 */
export const scrollToFirstError = () => {
  document
    .querySelector(".ant-form-item-has-error")
    ?.scrollIntoView({ behavior: "smooth", block: "center" });
};
/**
 * @description 报错定位
 * @param elId 需要定位的ID
 */
export function scrollIntoView(elId: string) {
  // @ts-ignore
  //新增 水平方向的居中滚动
  console.log("scrollIntoView", `${elId}`, document.getElementById(`${elId}`));
  document
    .getElementById(elId)
    ?.scrollIntoView({ block: "center", inline: "center" });
}
/**
 *
 * @param pwd 密码加密
 * @returns
 */
export const encryptPassword = (pwd: string) => {
  return Buffer.from(pwd).toString("base64");
};

/**
 *
 * @param array, 将数组转成对象
 */

export const getEnumObject = (array: Constant[]) => {
  return mustArray(array).reduce((init, curr) => {
    return { ...init, [curr.value]: curr.label };
  }, {});
};

/**
 * 从给定的 `Blob` 缓冲区下载文件，可选地指定文件名和回调函数。
 *
 * @param buffer - 要下载的文件数据所在的 `Blob`。
 * @param fileName - 下载文件的期望文件名。如果不提供，则默认为 "download"。
 * @param callback - 下载过程成功完成后可选执行的回调函数。
 */
export function downloadFromBuffer(
  buffer: Blob,
  fileName?: string,
  callback?: any
) {
  const downloadElement = document.createElement("a");
  const blob = new Blob([buffer]);
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = fileName || "download";
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
  if (isFunction(callback)) {
    callback();
  }
}

/**
 *
 * @param values, 去掉前后空格
 */
export function handleRemoveTrim(values: any) {
  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === "string") {
      values[key] = value.trim();
    }
    if (value === "") {
      values[key] = undefined;
    }
  });
}

/**
 * 对给定的数组进行树状映射，递归地对每个元素应用回调函数，并根据层级和唯一键重新组织结果。
 * @param xs 待处理的数组。
 * @param cb 应用于每个元素的回调函数，该函数会根据元素及其在树中的位置来产生新的值。
 * @param level 当前处理元素的层级，根元素的层级为0，默认为0。
 * @param parent 当前处理元素的父元素，默认为null。
 * @param uniqKey 当前处理元素的唯一键，可用于子元素的标识，默认为undefined。
 * @returns 返回一个新数组，其中包含了应用回调函数并根据层级和唯一键重新组织后的元素。
 */
export type treeMapCbFn = (
  x: any,
  idx: number,
  xs: any[],
  level: number,
  parent?: any,
  uniqKey?: any
) => any;
export function treeMap(
  xs: any[],
  cb: treeMapCbFn,
  level = 0,
  parent = null,
  uniqKey?: any
): any[] {
  if (isEmptyArray(xs)) return [];
  return xs
    .map((x, idx) => {
      if (notEmptyArray(x?.children)) {
        return cb(
          {
            ...x,
            children: treeMap(
              x?.children,
              cb,
              level + 1,
              x,
              `${uniqKey ? `${uniqKey}-` : ""}level${level}-${idx}`
            ),
          },
          idx,
          xs,
          level,
          parent,
          `${uniqKey ? `${uniqKey}-` : ""}level${level}-${idx}`
        );
      }
      return cb(
        x,
        idx,
        xs,
        level,
        parent,
        `${uniqKey ? `${uniqKey}-` : ""}level${level}-${idx}`
      );
    })
    .filter(isExist)
    .flat(1);
}

/**
 * 获取当前时间 或 根据传入时间格式化
 * @param time 时间
 * @param format 时间格式
 */
export const getFormatDate = (
  time?: number | string,
  format: string = "YYYY-MM-DD HH:mm:ss"
) => {
  return time ? dayjs(time).format(format) : dayjs().format(format);
};

export const curry = (fn: Function) => {
  const arity = fn.length;
  return function $curry(...args: any[]): any {
    if (args.length < arity) {
      return $curry.bind(null, ...args);
    }
    return fn(...args);
  };
};

export const sessionSet = curry((key: string, value: any) => {
  if (typeof value !== "object") {
    throw new Error("sessionSet的value类型必须为object");
  }
  let data = value;
  const cache = sessionStorage.getItem(key);
  if (cache) {
    data = Object.assign(JSON.parse(cache), value);
  }
  sessionStorage.setItem(key, JSON.stringify(data));
});

export const sessionGet = (key: string) => {
  return (key2?: string): null | any => {
    let cache = sessionStorage.getItem(key);
    if (!cache) return null;
    cache = JSON.parse(cache);
    return key2 ? cache![key2] : cache;
  };
};

export const isChineseString = (str: string) => {
  return /[\u4e00-\u9fff]/.test(str);
};

/**
 * 禁用当天之后的时间
 * @param time 时间
 * @param format 时间格式
 */
export const disableFutureDates = (current: any) => {
  const todayEnd = dayjs().endOf("day");
  return dayjs(current).isAfter(todayEnd);
};

/**
 * @getCurrentLocation 获取用户当前坐标
 */

export const getCurrentLocation = async () => {
  const promise = new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          isReject: false,
        });
      },
      (error) => {
        const { lat, lng } = window?.env?.REACT_CENTER || {};
        // 如果获取不了位置 则重置为成都
        resolve({
          lat: +lat || 50.110924,
          lng: +lng || 8.682127,
          isReject: true,
        });
      },
      {
        timeout: 3000,
        maximumAge: 0,
      }
    );
  });
  const location: any = await promise;
  console.log(location, "sfppsdpfpp");
  return location || { lat: 30.683480999999997, lng: 103.97675299999992 };
};

export type LanguageCode =
  | "en-US"
  | "zh-CN"
  | "de-DE"
  | "fr-FR"
  | "th-TH"
  | "es-ES"
  | "it-IT"
  | "pl_PL"
  | "locale"
  | string;
export const returnHadDefineLanguageType = (langKey: LanguageCode) => {
  if (langKey.startsWith("en")) {
    return "en-US";
  }
  if (langKey.startsWith("zh")) {
    return "zh-CN";
  }
  if (langKey.startsWith("de")) {
    return "de-DE";
  }
  if (langKey.startsWith("fr")) {
    return "fr-FR";
  }
  if (langKey.startsWith("th")) {
    return "th-TH";
  }
  if (langKey.startsWith("es")) {
    return "es-ES";
  }
  if (langKey.startsWith("it")) {
    return "it-IT";
  }
  if (langKey.startsWith("pl")) {
    return "pl-PL";
  }
  return "en-US";
};

// 通过可以获取当前语言
export const getCurrentLanguage = (key: string): LanguageCode => {
  let currentLanguage = "";
  if (key === "sysWebLanguage") {
    const defaultLanguage = navigator.language;
    currentLanguage = returnHadDefineLanguageType(defaultLanguage);
  } else {
    currentLanguage = key;
  }
  return currentLanguage;
};

export interface DataSourceType {
  id: string;
  label: string | React.ReactNode;
  val?: string;
  icon?: any;
  isCheck?: boolean;
  canSelect: boolean;
  children?: DataSourceType[];
}
export const langList: DataSourceType[] = [
  {
    id: "0",
    label: "comSysWeb",
    val: "sysWebLanguage",
    isCheck: false,
    canSelect: true,
  },
  // {
  //   id: "1",
  //   label: "简体中文",
  //   val: "zh-CN",
  //   isCheck: false,
  //   canSelect: true,
  // },
  {
    id: "2",
    label: "English",
    val: "en-US",
    isCheck: false,
    canSelect: true,
  },
  {
    id: "3",
    label: "Deutsch",
    val: "de-DE",
    isCheck: false,
    canSelect: true,
  },
  {
    id: "4",
    label: "Français",
    val: "fr-FR",
    isCheck: false,
    canSelect: true,
  },
  {
    id: "5",
    label: "ภาษาไทย",
    val: "th-TH",
    isCheck: false,
    canSelect: true,
  },
  {
    id: "6",
    label: "Español",
    val: "es-ES",
    isCheck: false,
    canSelect: true,
  },
  {
    id: "7",
    label: "Italiano",
    val: "it-IT",
    isCheck: false,
    canSelect: true,
  },
  {
    id: "8",
    label: "Polski",
    val: "pl-PL",
    isCheck: false,
    canSelect: true,
  },
];

// 当没有默认语言时，如果我们有预制语言就用我们的，没有就用英语

export const judgeLanguage = () => {
  // return "zh-CN"
  const isExistListLang =
    langList.findIndex((item) => {
      const text = `${item.val}`.split("-");
      return navigator?.language.includes(text[0]);
    }) === -1 && !navigator?.language.includes("zh");
  const localLang = localGetString(CHANGE_LANG);
  if (isExist(localLang)) {
    return localLang;
  }
  return isExistListLang
    ? "en-US"
    : returnHadDefineLanguageType(navigator?.language); // 把语言转换一下，取出来的浏览器语言可能只有前半部分，用单独的函数转换成我们系统的val语言
};

export const enum OPERATE_BUTTON_TYPE {
  MODIFY = "MODIFY",
  CLEAR = "CLEAR",
  DELETE = "DELETE",
  TRANSLATION = "TRANSLATION",
  ST = "ST",
  SP = "SP",
  VIEW = "VIEW",
  DOWNLOAD = "DOWNLOAD",
  RANGE = "RANGE",
  HIDDEN = "HIDDEN",
  DISPLAY = "DISPLAY",
  SWITCH = "SWITCH",
  // MORE = "MORE",
}

export enum StatusType {
  gray = "gray", // 离线色 灰色
  success = "success", // 绿色
  warning = "warning", // 橙色
  secondary = "cSecondary", // 次要橙色
  error = "error", // 错误
  info = "info", // 提示
  inProgress = "inProgress", // 进行中
  blue = "blue",
  orange = "orange",
  prompt = "prompt", //提示
}
export const statusBorderColor = {
  cWarning: "border-cError", // 黄色
  cSuccess: "border-cSuccess",
  cbgGray: "border-cGray",
};
export const statusLightColorBg = {
  cWarning: "bg-cError", // 黄色
  cSuccess: "bg-bgSuccess",
  cbgGray: "bg-bgGray",
};

// 微逆状态
export const enum MiStatus {
  offline = 0,
  connection,
  standby,
  commandshutDown,
  abnormalShutdown,
  waitingLight,
  upgrading,
}

export enum GroupType {
  factory = 1, // 厂家
  installer = 2, // 安装商
  family = 3, // 业主
  selfInstaller = 4, // 自安装者
  unknown = -1,
}

export enum StSp {
  error = 0,
  success = 1,
}

export const StSpStatusType: { [key: string]: "success" | "info" } = {
  [StSp.success]: "success", // 显示
  [StSp.error]: "info", // 隐藏
};

import {
  forwardRef,
  Fragment,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import { GAODE_KEY, GAODE_SAFETY_KEY } from "../../config";
import { Select, Button } from "antd";
import debounce from "lodash/debounce";
import React from "react";
import { useTranslation } from "react-i18next";

interface MapContainerProps {
  addressInfo: any;
  height?: number;
  container?: string;
  isEdit?: boolean;
  onChooseChange?: (value?: any) => void;
}

export interface RefProps {
  onConfirm?: (value?: any) => void;
  onReset?: () => void;
}

let mapInstance: any = null;

export default forwardRef((props: MapContainerProps, ref: Ref<RefProps>) => {
  const isCNFlag =
    window?.env?.REACT_APP_ISCN || process.env.REACT_APP_ISCN === "1";
  const { t } = useTranslation();
  const mapRef = useRef<any>(null);
  const googleMapRef = useRef<any>(null);
  const googleMarkerRef = useRef<any>(null);
  const palceRef = useRef<any>(null);
  const markerRef = useRef<any>(null);
  const [curChooseAddr, setCurChooseAddr] = useState<any>([]);
  const [curChooseGoogleAddr, setCurChooseGoogleAddr] = useState<any>({});
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [selectValue, setSelectValue] = useState<any>(undefined);
  const [selectNodes, setSelectNodes] = useState<any>({});
  const {
    isEdit = false,
    addressInfo = {},
    height = 150,
    container = "container",
    onChooseChange,
  } = props;

  useImperativeHandle(ref, () => ({
    onConfirm,
    onReset,
  }));

  function onReset() {
    setCurChooseAddr([addressInfo.longitude, addressInfo.latitude]);
    if (isCNFlag) {
      const ins = new mapInstance.LngLat(
        addressInfo.longitude,
        addressInfo.latitude
      );
      markerRef?.current?.setPosition(ins);
      mapRef?.current?.setCenter(ins);
    } else {
      const position = {
        lat: addressInfo.latitude,
        lng: addressInfo.longitude,
      };
      if (googleMapRef?.current) {
        googleMapRef.current.panTo(position);
        googleMarkerRef.current.position = position;
      }
    }
  }

  function onConfirm(record: any) {
    if (isCNFlag) {
      const geocoder = new mapInstance.Geocoder();
      geocoder.getAddress(curChooseAddr, function (status: any, result: any) {
        console.log(result, "12121");
        if (status === "complete" && result.info === "OK") {
          const { addressComponent, formattedAddress } = result.regeocode || {};
          onChooseChange?.({
            address: formattedAddress,
            ...addressComponent,
            longitude: curChooseAddr[0],
            latitude: curChooseAddr[1],
          });
        } else {
        }
      });
    } else {
      // 创建Geocoder对象
      const geocoder = new google.maps.Geocoder();
      // 根据坐标获取地址信息
      geocoder.geocode({ location: curChooseGoogleAddr }, (results, status) => {
        if (status === "OK") {
          if (results?.[0]) {
            const addrArr = results[0]?.address_components || [];
            let country = "";
            let province = "";
            let city = "";
            let postCode = "";
            addrArr.forEach((item) => {
              if (item?.types?.includes("country")) {
                country = item.long_name;
              }
              if (item?.types?.includes("administrative_area_level_1")) {
                province = item.long_name;
              }
              if (item?.types?.includes("locality")) {
                city = item.long_name;
              }
              if (item?.types?.includes("postal_code")) {
                postCode = item.long_name;
              }
            });
            onChooseChange?.({
              address: results[0].formatted_address,
              city,
              province,
              country,
              zipCode: postCode,
              longitude: curChooseAddr[0],
              latitude: curChooseAddr[1],
            });
            // 在此处添加您的处理逻辑
          } else {
          }
        } else {
        }
      });
    }
  }

  useEffect(() => {
    if (addressInfo && addressInfo.longitude != undefined) {
      setCurChooseAddr([addressInfo.longitude, addressInfo.latitude]);
      if (isCNFlag) {
        initGaodeMap(addressInfo);
      } else {
        initGoogleMap(addressInfo);
      }
    }
    return () => {
      mapRef?.current?.destroy();
    };
  }, [addressInfo]);

  const initGaodeMap = (addressInfo: any) => {
    // @ts-ignore
    window._AMapSecurityConfig = {
      securityJsCode: GAODE_SAFETY_KEY,
    };
    console.log(addressInfo, "addressInfo");
    AMapLoader.load({
      key: GAODE_KEY,
      version: "2.0",
      plugins: ["AMap.Marker", "AMap.Geocoder", "AMap.PlaceSearch"],
    })
      .then((AMap) => {
        mapInstance = AMap;
        mapRef.current = new AMap.Map(container, {
          zoom: 17,
          center: [addressInfo.longitude, addressInfo.latitude],
        });

        markerRef.current = new AMap.Marker({
          position: [addressInfo.longitude, addressInfo.latitude],
        });
        mapRef.current?.add(markerRef.current);
        mapRef.current?.on("click", (e: any) => {
          if (isEdit) {
            const { lng, lat } = e.lnglat;
            markerRef.current?.setPosition(new AMap.LngLat(lng, lat));
            setCurChooseAddr([lng, lat]);
          }
        });

        palceRef.current = new AMap.PlaceSearch({
          pageSize: 5,
          pageIndex: 1,
          city: "成都",
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const initGoogleMap = async (addressInfo: any) => {
    const position = { lng: addressInfo.longitude, lat: addressInfo.latitude };
    // @ts-ignore
    const { Map } = await google.maps.importLibrary("maps");
    // @ts-ignore
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    googleMapRef.current = new Map(document.getElementById(container), {
      center: position,
      zoom: 16,
      mapTypeControl: false,
      mapId: "DEMO_MAP_ID",
    });

    googleMarkerRef.current = new AdvancedMarkerElement({
      map: googleMapRef.current,
      position: position,
    });

    if (isEdit) {
      googleMapRef.current.addListener("click", (event: any) => {
        googleMarkerRef.current.position = event.latLng;
        setCurChooseGoogleAddr(event.latLng);
        googleMapRef.current.panTo(event.latLng);
      });
    }
  };

  const searchChange = debounce(async (val) => {
    setSelectValue(val);
    if (isCNFlag) {
      palceRef.current?.search(val, (status: any, result: any) => {
        if (result && result?.info === "OK") {
          const { poiList: { pois = [] } = {} } = result;
          setSelectOptions(pois);
        }
      });
    } else {
      let request = {
        query: val,
        fields: ["name", "geometry"],
      };
      let service = new google.maps.places.PlacesService(googleMapRef.current);

      service.findPlaceFromQuery(request, function (results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          const location = results?.[0]?.geometry?.location;
          const latitude = location?.lat();
          const longitude = location?.lng();
          setSelectOptions([
            {
              id: 211,
              name: results?.[0]?.name,
              location: {
                lng: longitude,
                lat: latitude,
              },
            },
          ]);
        }
      });
    }
  }, 1000);

  return (
    <Fragment>
      {isCNFlag ? (
        <div id={container} style={{ height }} className=" relative rounded-lg">
          {isEdit && (
            <div className="absolute z-10 left-6 top-6">
              <Select
                showSearch
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
                style={{ width: "300px" }}
                placeholder={t("companyPleaseSelectAddress")}
                value={selectValue}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={(val) => searchChange(val)}
                onChange={(val) => {
                  const findObj = selectOptions?.find((obj) => obj.id === val);
                  if (findObj) {
                    setSelectValue(findObj?.name);
                    setSelectNodes(findObj);
                    // setCurChooseAddr([
                    //   findObj.location.lng,
                    //   findObj.location.lat,
                    // ]);
                    // const ins = new mapInstance.LngLat(
                    //   findObj.location.lng,
                    //   findObj.location.lat
                    // );
                    // markerRef.current?.setPosition(ins);
                    // mapRef.current?.setCenter(ins);
                  }
                }}
                notFoundContent={null}
                options={selectOptions}
              />
              <Button
                type="primary"
                className="ml-2"
                onClick={() => {
                  if (Object.keys(selectNodes).length > 0) {
                    setCurChooseAddr([
                      selectNodes.location.lng,
                      selectNodes.location.lat,
                    ]);
                    const ins = new mapInstance.LngLat(
                      selectNodes.location.lng,
                      selectNodes.location.lat
                    );
                    markerRef.current?.setPosition(ins);
                    mapRef.current?.setCenter(ins);
                  }
                }}
              >
                {t("comSearch")}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className=" relative">
          <div
            id={container}
            className="rounded-lg"
            style={{ height, width: "100%" }}
          ></div>
          {isEdit && (
            <div className="absolute z-[10000] left-6 top-6">
              <Select
                showSearch
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
                value={selectValue}
                placeholder={t("companyPleaseSelectAddress")}
                style={{ width: "300px" }}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={(val) => searchChange(val)}
                onChange={(val) => {
                  const findObj = selectOptions?.find((obj) => obj.id === val);
                  if (findObj) {
                    setSelectValue(findObj?.name);
                    setCurChooseAddr([
                      findObj.location.lng,
                      findObj.location.lat,
                    ]);
                    const position = {
                      lat: findObj.location.lat,
                      lng: findObj.location.lng,
                    };
                    googleMapRef.current.panTo(position);
                    googleMarkerRef.current.position = position;
                  }
                }}
                notFoundContent={null}
                options={selectOptions}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
});

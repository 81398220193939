import { Button, Modal } from "antd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import MapViewComponent, { RefProps } from "../MapViewComponent";
import React from "react";

interface MapModalComponentProps {
  isOpen: boolean;
  isEdit?: boolean;
  container?: string;
  addressInfo: any;
  height?: number;
  closeModalFn?: (newVal: any) => void;
  chooseChange?: (newVal: any) => void;
}

export default function MapModalComponent(props: MapModalComponentProps) {
  const {
    addressInfo = {},
    height = 200,
    isOpen,
    closeModalFn,
    chooseChange,
    container,
    isEdit = true,
  } = props;
  const mapRef = useRef<RefProps>(null);
  const { t } = useTranslation();
  return (
    <Modal
      centered={true}
      title={t("companyPleaseSelectAddress")}
      width={"50%"}
      className=" min-w-[700px]"
      open={isOpen}
      destroyOnClose
      onCancel={() => {
        closeModalFn?.(false);
        mapRef?.current?.onReset?.();
      }}
      footer={[
        <Button
          className="!px-[1rem] !rounded-[0.25rem] h-[2.25rem]"
          key="back"
          size="middle"
          onClick={() => {
            closeModalFn?.(false);
            mapRef?.current?.onReset?.();
          }}
        >
          {t("comCancel")}
        </Button>,
        <Button
          className="!px-[1rem] !rounded-[0.25rem] h-[2.25rem]"
          key="submit"
          type="primary"
          size="middle"
          onClick={() => {
            mapRef?.current?.onConfirm?.();
            closeModalFn?.(true);
          }}
        >
          {t("comConfirm")}
        </Button>,
      ]}
    >
      <div className="w-500 relative">
        <MapViewComponent
          ref={mapRef}
          isEdit={isEdit}
          onChooseChange={(val) => {
            console.log(val, "12121");
            chooseChange?.(val);
          }}
          height={height}
          container={container}
          addressInfo={addressInfo}
        />
      </div>
    </Modal>
  );
}

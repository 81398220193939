import { ReactNode } from "react";
import {
  BasicInput,
  BasicSelect,
  BasicDatePicker,
  BasicTextArea,
  BasicCascader,
  BasicDateRangePicker,
  BasicInputNumber,
  BasicRadio,
  BasicUpload,
  BasicCheckbox,
  BasicTreeSelect,
  BasicPassword,
  BasicVerificationCode,
} from "./inputTypeFile/index";

//Input 框类型
export const InputType = {
  Text: "Text",
  DataPicker: "DataPicker", //选择时间
  DateRangePicker: "DateRangePicker", //选择时间区间
  Cascader: "Cascader", //级联选择
  Select: "Select", //下拉选择
  Number: "Number", //输入框为数字
  Radio: "Radio", //单选框
  Checkbox: "Checkbox", //多选框
  Upload: "Upload", //上传附件
  TextArea: "TextArea", //文本
  TreeSelect: "TreeSelect", //树级下拉选
  Password: "Password", // 密码框
  VerificationCode: "VerificationCode", // 获取验证码
};

export interface selectDataType {
  label: string | ReactNode;
  value: string | number;
}
interface cascaderDataType extends selectDataType {
  children?: cascaderDataType[];
}

export interface radioDataType {
  label: string;
  value: string | number;
}

export interface checkDataType extends selectDataType {
  radioData: checkDataType[];
}

export interface inputDefaultStyleType {
  width?: number;
  height?: number | string;
}
export interface extarPropsType {
  inputDefaultStyle?: inputDefaultStyleType;
  name?: string;
}

export interface BoxConfig {
  id?: string;
  key?: string | number;
  inputOptions?: any;
  selectData?: selectDataType[];
  cascaderData?: cascaderDataType[];
  radioData?: radioDataType[];
  checkData?: checkDataType[];
  treeData?: any;
  inputType?: string;
  value?: any;
  disableDate?: boolean;
  disabled?: boolean;
  customDom?: any
}

const inputContent = {
  [InputType.DataPicker]: BasicDatePicker,
  [InputType.DateRangePicker]: BasicDateRangePicker,
  [InputType.Cascader]: BasicCascader,
  [InputType.Select]: BasicSelect,
  [InputType.Number]: BasicInputNumber,
  [InputType.Radio]: BasicRadio,
  [InputType.Upload]: BasicUpload,
  [InputType.TextArea]: BasicTextArea,
  [InputType.Checkbox]: BasicCheckbox,
  [InputType.TreeSelect]: BasicTreeSelect,
  [InputType.Password]: BasicPassword,
  [InputType.VerificationCode]: BasicVerificationCode,
};

export const getInput = (
  item: BoxConfig,
  {
    inputDefaultStyle,
    name,
  }: { inputDefaultStyle?: inputDefaultStyleType; name: string }
) => {
  const { inputType = null } = item;
  if (inputType) {
    console.log(inputType, "inputType")
    return inputContent[inputType](item, { inputDefaultStyle, name });
  }
  console.log(item, '32432432')
  if (item.customDom) return item.customDom()
  return BasicInput(item, { inputDefaultStyle, name });
};

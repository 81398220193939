import { useMemoizedFn } from "ahooks";
import { Button, Divider, Form, Result, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { mutationActiveAccount, mutationActivateDetail } from "@/services/user";
import { BasicAlert, CheckAgreement, encryptPassword, FocusText, FormWrapper, useTheme, useValidation } from "common";
import { BASE_PREFIX } from "common/config";
import { FONT_WEIGHT, MinorText, TEXT_SIZE } from "common/components/BasicText";
import { InputType } from "common/components/QueryBar/getInput";
import { isExist, notExist } from "common/utils";
import { useToAgreement } from "../login/hooks/useToAgreement";
import _ from "lodash";

interface InputContent {}
const AccountActive: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>();
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [openAgreementTips, setOpenAgreementTips] = useState(false);
  const { agreementContent } = useToAgreement();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [searchParams] = useSearchParams();
  const [disableBtn, setDisabledBtn] = useState(true);
  const [values, setValues] = useState<InputContent>({
    newPassword: "",
    okPassword: "",
  });

  const queryDetail = useMemoizedFn(async () => {
    try {
      const code = searchParams.get("code") || "";
      const type = searchParams.get("type");
      const mutationResult = await mutationActivateDetail({
        activateCode: code,
        type: Number(type),
      });
      if (mutationResult?.code === 200) {
        setUserEmail(mutationResult?.data?.account || "--");
        setDisable(false);
      } else {
        setDisable(true);
      }
    } catch (error) {}
  });

  useEffect(() => {
    queryDetail();
  }, []);

  // 激活账号 只显示浅色
  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add(`theme-light`);
      document.body.classList.add("theme-default");
      document.body.classList.remove("theme-dark");
    }
  }, []);

  // 表单校验成功
  const onFinish = _.debounce(async (activeInfo: User.AccountActiveType = {}) => {
    try {
      if (!agreementCheck) {
        setOpenAgreementTips(true);
        return false;
      }
      const { newPassword } = activeInfo;
      const code = searchParams.get("code") || "";
      const type = searchParams.get("type");
      if (!code) {
        message.destroy();
        message.open({
          type: "warning",
          content: t("activeCodeReq"),
        });
        return;
      }
      if (!type || !Number(type)) {
        message.destroy();
        message.open({
          type: "warning",
          content: t("activeTypeReq"),
        });
        return;
      }
      // if (newPassword !== okPassword) {
      //   message.destroy();
      //   message.open({
      //     type: "warning",
      //     content: t("activePwdInconsistent"),
      //   });
      //   return;
      // }

      // if (!newPassword || newPassword.trim() === "") {
      //   return;
      // }
      setLoading(true);
      const mutationResult = await mutationActiveAccount({
        activateCode: code,
        encrypted: true,
        password: encryptPassword(newPassword!.trim()),
        type: Number(type),
      });
      setLoading(false);
      if (mutationResult?.code === 200) {
        setDisable(true);
        setIsActive(true);
      }
    } catch (error) {
      setLoading(false);
    }
  }, 500);

  const inputOnChange = (inputContent: InputContent) => {
    const currentValues = { ...(values || {}), ...inputContent };
    setDisabledBtn(!Object.values(currentValues).every((value) => isExist(value)));
    setValues(currentValues);
  };
  const formItems = [
    {
      name: "newPassword",
      required: true,
      inputType: InputType.Password,
      requiredName: t("activeNewPwd"),
      options: {
        rules: [
          {
            validator: (_: any, value: string, cb: any) => {
              if (value && !useValidation.validateSteinPwd(value)) {
                return Promise.reject(new Error(t("userInfoPasswordPolicy")));
              } else {
                cb();
              }
            },
          },
        ],
      },
      inputOptions: {
        placeholder: t("userInfoNewPassword"),
        style: {
          height: 40,
          background: "transparent",
        },
      },
    },
    {
      name: "okPassword",
      required: true,
      inputType: InputType.Password,
      useRequiredRules: false,
      requiredName: t("userInfoPwdMismatch"),
      options: {
        validateTrigger: "onBlur",
        rules: [
          {
            validator: (_: any, value: string, callback: any) => {
              const newPassword = form.getFieldValue("newPassword");
              if (notExist(value)) {
                return Promise.reject(new Error(t("registerPwdConfirmPlease")));
              }
              if (JSON.stringify(value) !== JSON.stringify(newPassword)) {
                callback(t("userInfoPwdMismatch"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      inputOptions: {
        placeholder: t("userInfoConfirmNewPassword"),
        style: {
          height: 40,
          background: "transparent",
        },
      },
    },
  ];

  return (
    <div className="h-full justify-center items-center overflow-y-auto box-border  bg-modulesBackground">
      {!isActive ? (
        <div className="w-full h-full flex justify-center items-center ipad:bg-modulesBackground ">
          <div className=" max-w-[520px] py-[3.75rem] px-[3rem]">
            <FocusText text={t("activeAccountTitle")} fontWeight={FONT_WEIGHT.SEMIBOLD} textSize={TEXT_SIZE.TOW_XL} />
            <BasicAlert text={t("activeAccountInfo")} isClose={false} type={"warning"} style={{ marginBottom: 36, marginTop: 24 }} />
            <FocusText
              text={userEmail || "--"}
              textSize={TEXT_SIZE.SM}
              className=" block py-[0.625rem] px-3 bg-selectedColor border border-solid border-dividingLine rounded-[4px] mb-[20px]"
            />
            <FormWrapper
              formStyle={{
                gridTemplateColumns: "1fr",
              }}
              form={form}
              formItems={formItems}
              onFinish={onFinish}
              submitEnter={true}
            />
            <div className=" py-4 h-full">
              <CheckAgreement
                check={agreementCheck}
                setCheck={setAgreementCheck}
                open={openAgreementTips}
                setOpen={setOpenAgreementTips}
                popoverProps={{
                  content: agreementContent,
                }}
                text={agreementContent}
              />
            </div>
            <Form onValuesChange={inputOnChange} form={form} name="control-hooks" onFinish={onFinish}>
              <Button loading={loading} htmlType="submit" disabled={disableBtn || disable} type="primary" className=" w-full h-10">
                {t("activeBtn")}
              </Button>
            </Form>
            <div className="mt-[3rem]">
              <FocusText text={t("activePasswordRules")} fontWeight={FONT_WEIGHT.MEDIUM} textSize={TEXT_SIZE.SM} />
              <Divider className=" my-[6px]" style={{ color: "var(--color-textDisable)" }} />
              <MinorText text={t("activeAccountRule")} textSize={TEXT_SIZE.SM} />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-[10%]">
          <Result
            status="success"
            title={t("activeSuc")}
            extra={[
              <Button
                onClick={() => {
                  window.location.href = `${BASE_PREFIX}/login`;
                }}
                size="large"
                type="primary"
                key="console"
              >
                {t("active2Login")}
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default AccountActive;

export interface CustomTokenType {
  primary?: string;
  textColor?: string;
  background?: string;
  cMainTxt: string;
  auxiliary?: string;
  colorText?: string;
  contentBg?: string;
  bodyColor?: string;
  purple?: string;
  antiqueWhite?: string;
  resetButton?: string;
  white?: string;
  barBgColor?: string;
  skylineAzure?: string;
  chartNormal?: string;
  chartNormalSecond?: string;
  chartFault?: string;
  chartOffline?: string;
  chartWillActive?: string;
  bgForm?: string;
  backgroundSide?: string;
  borderSecond?: string;
  disableBtn?: string;
  successColor?: string;
  successBgColor?: string;
  cFormErrorRed?: string;
  bgInput?: string;
  textBlack?: string;
  gray?: string;
  green?: string;
  red?: string;
  flowLine?: string;
}

const currentTheme: "dark" | "light" =
  (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";

const themeList = {
  themeDefault: {
    components: {
      Custom: {
        // ------chat图标专属颜色（因为chat目前没有什么办法直接使用定义好的颜色，所以只能先从这边定义好取值）-------
        chartNormal: "#0FC593",
        chartNormalSecond: "#cce9cd",
        chartFault: "#D3564D",
        chartOffline: "#6C6E73",
        chartWillActive: "#D2D5D6",
        // --------------状态色---------------
        gray: "#828483",
        green: "#21B878",
        red: "#FBDCD5",
        // 流动线
        flowLine: currentTheme === "light" ? "#1D8FF8" : "#6ACBCA",
      },
      // 菜单栏配置
      Menu: {
        horizontalItemBorderRadius: 60, // 菜单圆角
        horizontalItemSelectedBg: "var(--color-primary)", // 菜单选中背景颜色
        itemSelectedColor: "var(--color-primary)", // 菜单项选中文字颜色
        itemSelectedBg: "var(--color-selectedColor)", // 菜单项选中背景颜色
        itemHoverBg: "var(--color-selectedColor)", // 菜单项hover背景颜色
        itemHoverColor: "var(--color-textMain)", // 菜单项hover文字颜色
        itemColor: "var(--color-textMain)", // 菜单项文字颜色
        popupBg: "var(--color-modulesBackground)", // 弹出框背景色
        horizontalItemHoverColor: "red",
        itemPaddingInline: 28,
      },
      // 按钮组件配置
      Button: {
        defaultBg: "transparent", // 默认按钮背景色
        defaultColor: "var(--color-textMinor)", // 默认按钮文字颜色
        defaultBorderColor: "var(--color-textAssist)", // 默认按钮边框色
        primaryShadow: "", // 主要按钮阴影
        contentFontSizeLG: 14,
        // paddingInlineLG: "6"
      },
      Pagination: {
        itemSize: 28,
        // itemBg: "var(--color-modulesBackground)", // 背景色
        itemActiveBg: "var(--color-dividingLine)", // 激活状态背景色
      },
      Table: {
        headerBg: "var(--color-tableHeader)", // 背景色
        headerBorderRadius: 0,
        borderColor: "var(--color-dividingLine)", // 表格边框/分割线颜色
        rowHoverBg: "var(--color-selectedColor)", // 表单hover
        rowSelectedBg: "var(--color-selectedColor)", // 选中
        rowSelectedHoverBg: "var(--color-selectedColor)", // 选中悬浮
        expandIconBg: "var(--color-modulesBackground)", // 展开图标背景色
        bodySortBg: "",
        headerSortActiveBg: "", // 表头排序激活态背景色
        headerSortHoverBg: "", // 表头排序激活态悬浮背景色
        lineHeight: "1rem",
      },
      Select: {
        optionSelectedBg: "var(--color-selectedColor)", // 选项选中背景色
        optionSelectedColor: "var(--color-primary)", // 选项选中文本色
        optionActiveBg: "var(--color-selectedColor)", // 选项hover背景色
        multipleItemBg: "var(--color-assistButtonBg)", // 多选标签背景色
        activeShadow: "",
        multipleItemHeight: 28,
        clearBg: "var(--color-textAssist)",
      },
      Cascader: {
        optionSelectedBg: "var(--color-selectedColor)",
      },
      Layout: {
        siderBg: "var(--color-modulesBackground)", // 侧边栏的容器背景色，
      },
      Form: {
        labelColor: "var(--color-textMain)", // 标签label颜色
      },
      Input: {
        // addonBg: "var(--color-textAssist)", // 前后标签背景色
        addonBg: "var(--color-tableColor)", // 前后标签背景色
        activeShadow: "",
      },
      TreeSelect: {
        nodeHoverBg: "var(--color-selectedColor)",
        nodeSelectedBg: "var(--color-selectedColor)",
      },
      DatePicker: {
        addonBg: "var(--color-textMinor)",
        activeShadow: "",
        cellActiveWithRangeBg: "var(--color-primary)", // 选取范围内的单元格背景色
      },
      Tabs: {
        itemColor: "var(--color-textAssist)", // 标签文本颜色
        itemSelectedColor: "var(--color-textMain)", // 标签选中文本颜色
      },
      Badge: {
        dotSize: 8,
        statusSize: 8,
      },
      Switch: {
        trackMinWidthSM: 24,
      },
      Modal: {
        headerBg: "var(--color-modulesBackground)", // 顶部背景色
        contentBg: "var(--color-modulesBackground)", // 内容背景色
        footerBg: "var(--color-modulesBackground)", // 底部背景色
      },
      tree: {
        directoryNodeSelectedBg: "var(--color-selectedColor)",
        nodeSelectedBg: "var(--color-selectedColor)",
      },
      Slider: {
        handleColor: "var(--color-primary)",
      },
      // Message: {
      //   contentBg: "red",
      //   contentPadding: "20px 30px"
      // }
    },
    token: {
      colorText: "var(--color-textMain)", // 文字颜色
      colorLink: "#4C77FE", // 链接文字颜色
      colorLinkHover: "#98B1FE", // 控制超链接悬浮时的颜色。
      colorBgContainer: "var(--color-modulesBackground)", // 组件的容器背景色，
      colorBgContainerDisabled: "var(--color-selectedColor)", // 组件的容器禁用背景色，
      colorBgElevated: "var(--color-dropdownBackground)", // 浮层容器背景色
      colorTextDescription: "var(--color-textMain)", // 文字描述颜色
      colorTextTertiary: "var(--color-textMain)", // 文字颜色
      colorTextPlaceholder: "var(--color-textAssist)", // 控制占位文本的颜色。
      colorTextDisabled: "var(--color-textAssist)", // 控制禁用状态下的文本颜色
      colorBorder: "var(--color-dividingLine)", // 默认使用的边框颜色, 用于分割不同的元素，例如：表单的分割线、卡片的分割线等。
      colorPrimaryBorder: "var(--color-dividingLine)", // 主色梯度下的描边用色，用在 Slider 等组件的描边上。
      colorBorderSecondary: "var(--color-dividingLine)", // 比默认使用的边框色要浅一级，此颜色和 colorSplit 的颜色一致。使用的是实色。
      colorIcon: "var(--color-textAssist)", // 控制弱操作图标的颜色，例如 allowClear 或 Alert 关闭按钮。 *
      controlHeightLG: 36,
      // controlHeightSM: 20,
      // sizeXL: 20

      // colorLink: "var(--color-primary)", // 	控制超链接的颜色。
    },
  },
};

export default themeList;

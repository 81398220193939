import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { FilterValue, GetRowKey, SorterResult } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Table } from "antd";
import { useDebounceFn } from "ahooks";
import { queryListData } from "../../services";
import { arrayToTree, isEmptyArray, isExist } from "../../utils";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";
import { isArray, isFunction } from "lodash";
import GlobalNoData from "../GlobalNoData";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

/**
 * @description 自定义列表组件
 * @param {string} baseUrl 请求数据的url
 * @param {ColumnsType<any>} columns 列表的列
 * @param {React.ReactNode} children 列表顶部内容
 * @param {boolean} showPagination 是否显示底部分页
 * @param {boolean} showLoading 是否显示table请求数据的加载状态，默认不填
 * @param {Function} onCheckBoxChange 多选改变时触发
 * @param {Function} onListDataChange 数据获取成功触发的函数
 * @param {string} className 类名
 * @param {number} paginationHeight 分页器的高度
 * @param {number} pageSize 一页的数据总数
 * @return React.ReactNode
 */

interface TablePropsType<T, K> {
  dataSource?: K[];
  columns: T;
  dataLevel?: number;
  height?: number;
  source?: string;
  isSort?: boolean;
  baseUrl: string;
  selectionType?: string;
  children?: React.ReactNode;
  showPagination?: boolean;
  className?: string;
  paginationHeight?: number;
  queryParams?: QueryType;
  pageSize?: number;
  rowKey?: string | GetRowKey<any>;
  showLoading?: boolean;
  showScroll?: boolean;
  scrollX?: number;
  isTree?: boolean;
  mountedLoad?: boolean;
  curSelectedRowKeys?: number[];
  onCheckBoxChange?: (value: any) => void;
  onListDataChange?: (value: any, allValue?: any) => void;
  getCheckboxProps?: (record: any) => {
    disabled: boolean;
    name: string;
  };
  [props: string]: any;
}
export interface QueryType {
  timeStamp?: number;
  [props: string]: any;
}

export default function RequestTable<T extends ColumnsType<any> | undefined, K>(
  props: TablePropsType<T, K>
) {
  const {
    isSort = false,
    selectionType = "checkbox",
    height,
    dataSource = [],
    source,
    children,
    dataLevel,
    columns,
    className,
    showPagination = true,
    showLoading = true,
    showScroll = true,
    pageSize = 20,
    queryParams,
    paginationHeight = 48,
    onCheckBoxChange,
    onListDataChange,
    curSelectedRowKeys = [],
    baseUrl = "",
    rowKey = "id",
    isTree = false,
    mountedLoad = true,
    getCheckboxProps,
    // showScrollX = false,
    ...otherProps
  } = props;

  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const [scrollY, setScrollY] = useState<number>(0);
  const [listData, setListData] = useState<K[]>(dataSource);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showTableLoading, setShowTableLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      pageSize,
      current: 1,
      total: 0,
      showSizeChanger: true,
      showTotal: (total: number) => {
        return <span>{t("paginationTotal", { num: total })}</span>;
      },
      showQuickJumper: true,
      pageSizeOptions: [10, 20, 30, 50],
      position: ["bottomRight"],
    },
  });
  useEffect(() => {
    getTableScroll();
    !queryParams?.timeStamp && mountedLoad && queryListDataFe(tableParams);
    window?.addEventListener("resize", getTableScroll);
    return () => {
      window?.removeEventListener("resize", getTableScroll);
    };
  }, []);

  useEffect(() => {
    console.log(queryParams, "queryParams");
    if (queryParams?.timeStamp) {
      const newTableParams = {
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: queryParams.collected ? tableParams.pagination?.current : 1,
        },
      };
      console.log(tableParams, "324342222");
      setTableParams(newTableParams);
      queryListDataFe(newTableParams);
    }
  }, [queryParams]);

  useEffect(() => {
    Array.isArray(curSelectedRowKeys) && setSelectedRowKeys(curSelectedRowKeys);
  }, [JSON.stringify(curSelectedRowKeys)]);

  useEffect(() => {
    setShowTableLoading(showLoading);
  }, [showLoading]);

  const { run: getTableScroll } = useDebounceFn(
    () => {
      if (height) {
        setScrollY(height);
      } else {
        const tableheaderDom = document.querySelector(
          ".auto-just-table .ant-table-header"
        );
        const { height: containerHeight = 0 } =
          containerRef?.current?.getBoundingClientRect() || {};
        const { height: searchHeight = 0 } =
          searchRef?.current?.getBoundingClientRect() || {};
        const { height: tableheaderHeight = 0 } =
          tableheaderDom?.getBoundingClientRect() || {};
        setScrollY(
          containerHeight -
            searchHeight -
            tableheaderHeight -
            (showPagination ? paginationHeight : 0) -
            3 -
            2
        );
      }
    },
    {
      wait: 500,
    }
  );

  const sortList = (listData: any[]) => {
    return listData?.sort((a: any, b: any) => {
      if (a.seq === null || a.seq === undefined) {
        return 1;
      } else if (b.seq === null || b.seq === undefined) {
        return -1;
      } else {
        return a.seq - b.seq;
      }
    });
  };

  const queryListDataFe = async (params: TableParams) => {
    try {
      setLoading(true);
      const queryResult = await queryListData(
        {
          pageIndex: params?.pagination?.current,
          pageSize: params?.pagination?.pageSize,
          ...queryParams,
        },
        baseUrl
      );
      setLoading(false);
      setLoader(true);
      if (queryResult?.code === 200) {
        let resData = queryResult?.data || [];
        if (isSort) {
          resData = sortList(resData);
        }
        if (dataLevel === 1) {
          if (isTree) {
            setListData(arrayToTree(cloneDeep(resData || [])));
          } else {
            setListData(resData || []);
          }
          onListDataChange?.(cloneDeep(resData || []), queryResult);
        } else {
          if (isTree) {
            setListData(arrayToTree(cloneDeep(resData?.data || [])));
          } else {
            setListData(resData?.data || []);
          }
          onListDataChange?.(cloneDeep(resData?.data || []), resData);
        }
        setTableParams({
          ...params,
          pagination: {
            ...params.pagination,
            total: resData?.total || 0,
          },
        });
        getTableScroll();
      }
    } catch (error) {
      setLoading(false);
      setLoader(true);
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    onCheckBoxChange?.(selectedRows);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<K>
  ) => {
    const newTabParams = {
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters,
      ...sorter,
    };
    setTableParams(newTabParams);
    queryListDataFe(newTabParams);

    // if (pagination?.pageSize !== tableParams?.pagination?.pageSize) {
    //   setListData([]);
    // }
  };
  return (
    <div ref={containerRef} className="flex flex-col h-full overflow-hidden">
      <div ref={searchRef} className={`${source === "home" ? "h-[80px]" : ""}`}>
        {children}
      </div>
      {/*  overflow-y-auto */}
      <div className="flex-1 flex w-full rounded-[6px]">
        {/* {listData.length === 0 && loading ? (
          <div className="w-full text-center">
            <Spin style={{ marginTop: "20%" }} />
          </div>
        ) : ( */}
        <Table
          scroll={{
            y:
              showScroll && !isEmptyArray(listData) && listData !== undefined
                ? scrollY
                : undefined,
            x: isExist(props.scrollX) ? props.scrollX : undefined,
          }}
          className={`auto-just-table flex-1 home-table ${className}`}
          columns={columns}
          rowKey={rowKey}
          locale={{
            emptyText:
              isArray(listData) && loader ? (
                <GlobalNoData />
              ) : (
                <div
                  style={{
                    height:
                      containerRef?.current?.getBoundingClientRect().height,
                  }}
                ></div>
              ),
          }}
          dataSource={listData}
          loading={showTableLoading && loading}
          pagination={showPagination ? tableParams?.pagination : false}
          // @ts-ignore
          rowSelection={
            onCheckBoxChange
              ? {
                  type: selectionType,
                  columnWidth: 70,
                  selectedRowKeys,
                  onChange: onSelectChange,
                  getCheckboxProps: isFunction(getCheckboxProps)
                    ? getCheckboxProps
                    : undefined,
                }
              : undefined
          }
          // @ts-ignore
          onChange={handleTableChange}
          {...otherProps}
        />
      </div>
    </div>
  );
}

import React from "react";
import { ConfigProvider } from "antd";
import { formatLangType } from "../../utils/langUtils";
interface CustomConfigProviderType {
  children: React.ReactNode;
  theme: any;
  langType: "zh-CN" | "en-US" | string;
  renderEmpty: any;
  loading?: boolean;
}

const CustomConfigProvider = (props: CustomConfigProviderType) => {
  const { children, theme, langType, renderEmpty } = props;
  const locale = formatLangType(langType);
  /**
   *消除ant内部强制波兰语简写周时使用了3个字母
   */
  if (locale) {
    locale.DatePicker!.lang.shortWeekDays = undefined;
  }

  return (
    <ConfigProvider locale={locale} theme={theme} renderEmpty={renderEmpty}>
      {children}
    </ConfigProvider>
  );
};

export default CustomConfigProvider;

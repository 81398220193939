import React, { useMemo } from "react";
import usePolicyAndTerm from "@/hooks/usePolicyAndTerm";
import { HighlightText } from "common";
import { t } from "i18next";

// 跳转隐私政策，用户注册也要用
export const useToAgreement = () => {
  const { jumpToPage } = usePolicyAndTerm();

  const agreementContent = useMemo(() => {
    return (
      <HighlightText
        highlightTextStyle={{ color: "var(--color-colorLink)" }}
        style={{ color: "var(--color-textMain)" }}
        text={t("loginAgree")}
        clickableIndexs={[0, 1]}
        onClickIndex={(tempIndex) => {
          console.log("tempIndex", tempIndex);
          if (tempIndex === 1) {
            jumpToPage("policy");
          } else {
            jumpToPage("term");
          }
        }}
      />
    );
  }, [jumpToPage]);

  return {
    agreementContent,
    jumpToPage,
  };
};

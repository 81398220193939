import { atom } from "jotai";
import { localGetString } from "@/utils";
import { localGetObj } from "common/utils";
import { USER_INFO } from "common/config";
import { USER_MENU_LIST, USER_TOKEN } from "z/config/constant";
const users: User.UserInfoType = localGetObj(USER_INFO) || {};
const userMenu: User.MenuListType[] = localGetObj(USER_MENU_LIST) || [];

const userTokenAtom = atom<string>(localGetString(USER_TOKEN) || "");
const userMenuList = atom<User.MenuListType[]>(userMenu);
const userInfoAtom = atom<User.UserInfoType>(users);
const permsListAtom = atom<string[]>([]);
export { userTokenAtom, userMenuList, userInfoAtom, permsListAtom };

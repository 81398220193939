import { queryPolicyTerm } from "@/services/user";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function usePolicyAndTerm() {
  const { t } = useTranslation();
  const [PolicyTerm, setPolicyTerm] = useState<User.PolicyTermType>({});
  useEffect(() => {
    fetchPolicyTerm();
  }, [t]);
  // 协议
  const fetchPolicyTerm = async () => {
    const res = await queryPolicyTerm();
    const { data } = res || {};
    if (data && !!data?.policyUrl) {
      setPolicyTerm(data);
    }
  };
  const jumpToPage = (type: "term" | "policy" | "cookie") => {
    if (type === "term" && PolicyTerm.termUrl) {
      window.open(PolicyTerm.termUrl);
    } else if (type === "policy" && PolicyTerm.policyUrl) {
      window.open(PolicyTerm.policyUrl);
    } else if (type === "cookie" && PolicyTerm.cookieUrl) {
      window.open(PolicyTerm.cookieUrl);
    } else {
      fetchPolicyTerm();
    }
  };

  return {
    PolicyTerm,
    jumpToPage,
  };
}
export default usePolicyAndTerm;
